import { css } from '@emotion/react';
import styled from '@emotion/styled';
import breakpoints from './Theme/breakpoints';
import InnerHtml from './InnerHtml';
import { lineHeights, letterSpacing } from './Theme/constants';

const tableStyling = css`
  table {
    border-collapse: collapse;
    width: 100%;
  }

  table, th, td {
    border: 1px solid;
  }

  th, td {
    padding: 8px;
  }
`;

const markdownStyling = css`
  font-family: var(--font-article);
  color: var(--black-high);
  line-height: 1.75rem;
  scroll-behaviour: smooth;

  p, a, div, span, u, strong, li, ul, ol, pre, code, hr, blockquote, table {
    font-size: var(--size-body1);
  }

  blockquote {
    font-family: var(--font-secondary);
    border-left: 4px solid var(--primary-color);
    padding-left: 16px;
    margin: 32px 32px 32px;
  }

  blockquote p {
    font-family: var(--font-secondary);
  }

  iframe {
    /* override inline image style */
    /* stylelint-disable-next-line declaration-no-important */
    width: 100vw !important;
    margin: -16px;
  }

  @media (min-width: ${breakpoints.sm}px) {
    iframe {
      /* override inline image style */
      /* stylelint-disable-next-line declaration-no-important */
      width: calc(100vw - 32px) !important;
      margin: 0 0 0 calc(-50vw + 291px);
    }
  }

  @media (min-width: ${breakpoints.md}px) {
    iframe {
      /* override inline image style */
      /* stylelint-disable-next-line declaration-no-important */
      width: calc(100vw - 40px) !important;
      margin: 0 0 0 calc(-50vw + 295px);
    }
  }

  @media (min-width: ${breakpoints.lg}px) {
    iframe {
      max-width: 700px;
      /* override inline image style */
      /* stylelint-disable-next-line declaration-no-important */
      width: 700px !important;
      margin: 0 0 0 -75px;
    }
  }

  iframe[src*="instagram.com"],
  iframe[src*="youtube.com"],
  iframe[src*="vimeo.com"] {
    max-width: 100%;
    margin: 0;
  }

  figure iframe {
    max-width: 100%;
  }

  a:not(.MuiTab-root) {
    color: var(--blue);
  }

  figcaption {
    font-family: var(--mdc-typography-font-family);
    font-size: var(--size-caption);
    color: var(--black-medium);
    text-align: center;
    padding: 0 16px;
  }

  h1 {
    font-size: var(--size-h3);
    line-height: ${lineHeights.headline3};
    letter-spacing: ${letterSpacing.headline3};
    margin: 48px 0 12px;
  }

  h2 {
    font-size: var(--size-h4);
    line-height: ${lineHeights.headline4};
    letter-spacing: ${letterSpacing.headline4};
    margin: 32px 0 8px;
  }

  h3 {
    font-size: var(--size-h5);
    line-height: ${lineHeights.headline5};
    margin: 24px 0 4px;
  }

  h4 {
    font-size: var(--size-h6);
    line-height: ${lineHeights.headline6};
  }

  h5 {
    line-height: 20px;
  }

  h6 {
    line-height: 16px;
  }

  h4, h5, h6 {
    margin: 16px 0 4px;
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: var(--font-secondary);
    color: var(--black-high);
  }

  /* stylelint-disable-next-line selector-type-no-unknown */
  .image-container img, .image-container progressive-img {
    width: 100%;
  }

  small {
    font-size: 88%;
  }

  .panel.panel-default {
    border: 1px solid var(--grey-light);
    padding: 16px;
  }

  .article-promo,
  .article-promo--alt {
    font-family: var(--font-primary);
    font-size: var(--size-body);
    line-height: 1.5rem;
    font-size: 14px;
    margin: 24px 0;

    a, header {
      color: var(--primary-color);
    }

    p, u, header, a {
      font-size: var(--size-body);
      line-height: 1.25rem;
    }

    header {
      font-weight: bold;
      text-transform: uppercase;
      margin: 0 0 8px;
    }

    p:last-of-type {
      margin: 0;
    }

    details {
      font-size: 14px;
      line-height: 1.5rem;

      .article-promo {
        background: #fff;
      }
    }
  }

  .article-promo--alt {
    border: 1px solid var(--primary-color);
    border-width: 1px 0;
    padding: 24px 0;
  }

  .article-promo {
    background-color: rgb(248, 248, 248);
    padding: 1.5rem;
    border: none;
    border-radius: 4px;

    p, p *, li, li * {
      line-height: 1.5rem;
      font-size: 14px;
    }
  }

  .embed-responsive {
    position: relative;
    display: block;
    height: 0;
    padding: 0;
    overflow: hidden;
  }

  .embed-responsive iframe {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    border: 0;
  }

  .embed-responsive-16by9 {
    padding-bottom: 56.25%;
  }

  .fu-google-maps {
    position: relative;
    overflow: hidden;
    height: 0;
    padding-bottom: 75%;
  }

  .fu-google-maps iframe {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    border: none;
  }

  details {
    display: inline;
    font-size: 16px;

    summary {
      position: relative;
      display: inline;

      .dropdown-icon,
      .asterisk-icon {
        cursor: pointer;
        background: #f8f8f8;
        border-radius: 50%;
        height: 20px;
        width: 20px;
        vertical-align: sub;
        display: inline-block;
        position: relative;
        outline: none;

        ::after {
          display: block;
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          margin: auto;
        }
      }

      .dropdown-icon {
        margin: 0 4px;

        ::after {
          content: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"%3E%3Cpath fill="rgb(234, 0, 81)" d="M8 12.043l7.413-8.087H.587z"/%3E%3C/svg%3E');
          top: -2px;
          width: 12px;
        }
      }

      .asterisk-icon {
        margin: 0 4px 0 0;

        ::after {
          content: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 6.01 6"%3E%3Cpath fill="rgb(234, 0, 81)" d="M.01 2.85l1.87.58L.64 5.2l1.13.8 1.24-1.66L4.24 6l1.13-.8-1.22-1.77 1.86-.58-.41-1.31-1.83.6V0H2.25v2.13L.42 1.54z"/%3E%3C/svg%3E');
          top: -6px;
          width: 6px;
        }
      }
    }
  }

  details[open] .dropdown-icon {
    transform: rotate(180deg);
  }

  > figure, > .image-container, > .embed-responsive, > .fu-google-maps {
    margin-left: -16px;
    margin-right: -16px;
  }

  @media (min-width: ${breakpoints.md}px) {
    > figure, > .image-container, > .embed-responsive, > .fu-google-maps {
      margin-left: 0;
      margin-right: 0;
    }

    > .image-container {
      min-height: 196px;
    }
  }
`;

const InnerHtmlStyling = styled(InnerHtml)<{
  className?: string;
  withTableStyles?: boolean;
}>`
  /* stylelint-disable-next-line no-descending-specificity */
  p, div, span, u, strong, ul, ol, code, hr, table, blockquote p {
    margin: 0 0 16px;
  }

  /* stylelint-disable-next-line no-descending-specificity */
  p, a, div, span, u, strong, li, ul, ol, pre, code, hr, blockquote, table {
    line-height: 1.75rem;
  }

  .fu-summary-container {
    display: flex;
    flex-direction: column;

    @media (min-width: ${breakpoints.md}px) {
      flex-direction: row;
      justify-content: space-between;
    }
  }

  .fu-summary-card {
    display: flex;
    justify-content: center;
    width: calc(100% - 12px);
    margin: 0;
    text-align: center;

    @media (min-width: ${breakpoints.md}px) {
      width: calc(50% - 12px);
    }
  }

  .fu-trending-icon-down,
  .fu-trendering-icon-up {
    background-position: center;
  }

  .grid {
    display: grid;
  }

  .grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));

    @media (min-width: ${breakpoints.md}px) {
      grid-template-columns: repeat(5, minmax(0, 1fr));
    }
  }

  .mt-6 {
    margin-top: 1.5rem;
  }

  .lg:mt-8 {
    @media (min-width: ${breakpoints.lg}px) {
      margin-top: 2rem;
    }
  }

  .bg-gray-50 {
    background-color: rgb(249, 250, 251);
    margin: 0;
  }

  .py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .max-h-12 {
    max-height: 3rem;
  }

  .mx-auto {
    margin-left: auto;
    margin-right: auto;
  }

  .rounded-lg {
    border-radius: 0.5rem;
  }

  .text-center {
    text-align: center;
  }

  .gap-1 {
    gap: .125rem;
  }

  ${markdownStyling}
  ${({ withTableStyles }) => withTableStyles && tableStyling}
`;

export {
  InnerHtmlStyling as default,
  markdownStyling,
};
