import React, { useEffect, useRef, MutableRefObject } from 'react';
import PropTypes, { InferProps } from 'prop-types';

const innerHtmlPropertyTypes = {
  dangerouslySetInnerHTML: PropTypes.shape({
    __html: PropTypes.string,
  }),
};

type InnerHtmlProperties = InferProps<typeof innerHtmlPropertyTypes>;

function InnerHtml(
  {
    dangerouslySetInnerHTML,
    ...rest
  }: InnerHtmlProperties,
) {
  const divReference = useRef<HTMLDivElement>() as
    MutableRefObject<HTMLDivElement>;
  const { __html: html } = dangerouslySetInnerHTML ?? {};

  useEffect(() => {
    if (!html || typeof document === 'undefined'
    || typeof document.createRange !== 'function') return;

    const slotHtml = document.createRange().createContextualFragment(html);
    divReference.current.innerHTML = '';
    divReference.current.append(slotHtml);
  }, [html]);

  return (
    <div
      {...rest}
      ref={divReference}
      {...(html ? { dangerouslySetInnerHTML: { __html: html } } : {})}
    />
  );
}

InnerHtml.propTypes = innerHtmlPropertyTypes;

export default InnerHtml;
